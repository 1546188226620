<template>
  <span>
    <v-row class="mx-auto">
      <v-col class="pb-0 pt-1" cols="6">
        <input-month 
          label="Competência de*"
          rules="required|min:7"
          :selectedMonth="data_de"
          @monthSelected="setDataDe"
        />
      </v-col>
      <v-col class="pb-0 pt-1" cols="6">
        <input-month 
          label="Competência até*"
          rules="required|min:7"
          :selectedMonth="data_ate"
          @monthSelected="setDataAte"
        />  
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-card class="elevation-2">
          <v-row class="mx-auto">
            <v-col class="pb-0 pt-1" cols="12">
              <v-checkbox 
                v-model="selecionar_todos_temas"
                label="Selecionar todos os temas"
              />
            </v-col>
          </v-row>
          <v-row class="mx-auto">
            <v-col 
              class="pb-0 pt-1" 
              md="2" sm="4" cols="6"
              v-for="(label, value) in temas_validos" 
              :key="value"
            >
              <v-checkbox 
                v-model="temas_selecionados"
                :label="label"
                :value="value"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto mt-5 mb-5">
      <v-expansion-panels v-model="expansion_panel_importacao_base_bruta">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Importação de Indicadores para Validação
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="todos_temas_selecionados">
              <v-col 
                cols="12" 
                v-if="importar_indicadores_para_validacao"
              >
                <v-alert dense outlined type="warning" class="mb-0 py-1 px-1">
                  <strong>Importante:</strong> caso optar pela Importação de Indicadores para Validação, esteja ciente de que quaisquer dados existentes para a Competência serão substituídos. 
                </v-alert>
              </v-col>
              <v-col 
                class="pb-0 pt-1" 
                cols="12"
              >
                <v-checkbox 
                  v-model="importar_indicadores_para_validacao"
                  label="Deseja importar os indicadores para que possam ficar disponíveis nas ferramentas de validações?"
                />
              </v-col>
            </v-row>
            <v-row class="mx-auto mt-3" v-else>
              <div>
                É necessário selecionar todos os temas para importar os indicadores para o processo de validação.
              </div>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row 
      class="mx-auto mt-5" 
      v-if="temas_validos_importacao_base_bruta.length"
    >
      <v-expansion-panels v-model="expansion_panel_importacao_base_bruta">
        <v-expansion-panel>
          <v-expansion-panel-header>Importação de Base Bruta</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="temas_selecionaveis_importacao_base_bruta.length > 0">
              <v-col cols="12">
                <v-alert dense outlined type="warning" class="mb-0 py-1 px-1">
                  <strong>Importante:</strong> caso optar pela Importação de Base Bruta, esteja ciente de que quaisquer dados existentes para a Competência e Tema(s) selecionado(s) serão substituídos. 
                </v-alert>
              </v-col>
              <v-col 
                class="pb-0 pt-1" 
                md="2" sm="4" cols="6" 
                v-for="tema in temas_selecionaveis_importacao_base_bruta" 
                :key="tema.value"
              >
                <v-checkbox 
                  v-model="temas_selecionados_importacao_base_bruta"
                  :label="tema.label"
                  :value="tema.value"
                />
              </v-col>
            </v-row>
            <v-row class="mx-auto mt-3" v-else>
              <div>
                Selecione acima ao menos 1 <a href="javascript:void(0)" @click.stop="dialog_temas_com_base_bruta = true">tema com base bruta</a> importável.
              </div>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-dialog
      max-width="480"
      v-model="dialog_temas_com_base_bruta"
    >
      <v-card>
        <v-card-title class="display-2">
          Temas que possuem Base Bruta
        </v-card-title>
        <v-card-text>
          <ul>
            <li 
              v-for="tema in temas_validos_importacao_base_bruta" 
              :key="tema.value"
            >
             {{ tema.label }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="secondary" 
            text
            @click="dialog_temas_com_base_bruta = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>

export default {
  name: 'ServicosV2',
  components: {
    InputMonth: () => import('@/components/general/InputMonth.vue'),  
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      data_de: '',
      data_ate: '',
      temas_validos: {
        Realizados: 'Realizados',
        Abertos: 'Entrantes',
        Suspensos: 'Suspensos',
        Pendentes: 'Pendentes',
        Compensados: 'Compensados'
      },
      selecionar_todos_temas: false,
      temas_selecionados: [],
      importar_indicadores_para_validacao: false,
      temas_validos_importacao_base_bruta: [
        { label: 'Realizados', value: 'Realizados' },
        { label: 'Entrantes', value: 'Abertos' },
        { label: 'Suspensos', value: 'Suspensos' },
        { label: 'Pendentes', value: 'Pendentes' },
        { label: 'Compensados', value: 'Compensados' }
      ],
      temas_selecionados_importacao_base_bruta: [],
      dialog_temas_com_base_bruta: false,
      expansion_panel_importacao_indicadores_validacao: undefined,
      expansion_panel_importacao_base_bruta: undefined
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.expansion_panel_importacao_indicadores_validacao = 0;
      this.expansion_panel_importacao_base_bruta = 0;
    }
  },
  computed: {
    temas_selecionaveis_importacao_base_bruta() {
      return this.temas_validos_importacao_base_bruta
        .filter(tema => this.temas_selecionados.includes(tema.value));
    },
    num_temas_selecionados() {
      return this.temas_selecionados.length;
    },
    num_temas_validos() {
      return Object.keys(this.temas_validos).length;
    },
    todos_temas_selecionados() {
      return this.num_temas_selecionados === this.num_temas_validos;
    }
  },
  methods: {
    setDataDe(data) {
      this.data_de = data;
      this.filtersUpdated();
    },
    setDataAte(data) {
      this.data_ate = data;
      this.filtersUpdated();
    },
    filtersUpdated() {
      this.$emit('filtersUpdated', {
        data_de: this.data_de,
        data_ate: this.data_ate,
        temas_selecionados: this.temas_selecionados,
        temas_selecionados_importacao_base_bruta: this.temas_selecionados_importacao_base_bruta,
        importar_indicadores_para_validacao: this.importar_indicadores_para_validacao
      });
    }
  },
  watch: {
    item(newVal) {
      Object.assign(this.$data, newVal.filtros);
    },
    temas_selecionados() {
      // Não permite importar base bruta de um tema não selecionado
      this.temas_selecionados_importacao_base_bruta = this.temas_selecionados_importacao_base_bruta
        .filter(tema => this.temas_selecionados.includes(tema));
      if (!this.todos_temas_selecionados) this.importar_indicadores_para_validacao = false;
      this.filtersUpdated();
    },
    temas_selecionados_importacao_base_bruta() {
      this.filtersUpdated();
    },
    importar_indicadores_para_validacao() {
      this.filtersUpdated();
    },
    selecionar_todos_temas() {
      this.temas_selecionados = this.selecionar_todos_temas 
        ? Object.keys(this.temas_validos) 
        : [];
    }
  }
}

</script>